<template>
  <iframe id="typeform-full" width="100%" height="100%" frameborder="0"
          allow="camera; microphone; autoplay; encrypted-media;"
          src="https://form.typeform.com/to/P1vSWNFQ?typeform-medium=embed-snippet"></iframe>
</template>

<script>
export default {
  name: 'Contact',

  data () {
    return {}
  },
  components: {},
  computed: {},
  methods: {},
  created () {

  }
}
</script>

<style lang="scss" scoped>
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: 0;
  }
</style>
